<template>

  <label class="checkbox-input">
    <input type="checkbox"
           @change="handleChange"
           :checked="value">

    <span><slot>{{label}}</slot><span v-show="required"
                                      class="input__required">*</span></span>
  </label>

</template>

<script>
export default {
  name: 'BaseFormCheckboxInput',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('change', value.target.checked)
    }
  }
}
</script>
