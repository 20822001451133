<template>
  <div class="card-title__content">
    <slot name="title"><h5>{{title}}</h5></slot>

    <div class="description">
    <slot name="description">
        <span class="hint">
       {{description}}
        </span>
    </slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BaseCardPartTitleContent',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
