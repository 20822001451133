var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vee-form-order",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('base-form',{staticClass:"form-order form-order--input-light",attrs:{"button-value":_vm.$t('PORTAL.FORMS.ORDER.SUBMIT.VALUE'),"is-gup":"","is-error":_vm.isRequestError,"button-disabled":invalid},on:{"submit":_vm.handleSubmit}},[_c('base-form-messages-server-errros',{attrs:{"store-path":_vm.$options.STORE_PATH}}),_c('validation-provider',{attrs:{"name":_vm.$t('PORTAL.FORMS.ORDER.INPUT.NAME.PLACEHOLDER'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-form-text-input',{attrs:{"placeholder":_vm.$t('PORTAL.FORMS.ORDER.INPUT.NAME.PLACEHOLDER'),"icon":_vm.$t('PORTAL.FORMS.ORDER.INPUT.NAME.ICON'),"label":_vm.$t('PORTAL.FORMS.ORDER.INPUT.NAME.LABEL'),"required":""},model:{value:(_vm.person),callback:function ($$v) {_vm.person=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"person"}}),_c('base-form-message-line-error',{attrs:{"errors":errors}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL'),"mode":"lazy","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-form-text-input',{attrs:{"placeholder":_vm.$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL'),"icon":_vm.$t('PORTAL.FORMS.ORDER.EMAIL.NAME.ICON'),"label":_vm.$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL'),"required":"","is-email":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('base-form-message-line-error',{attrs:{"errors":errors}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('PORTAL.FORMS.ORDER.INPUT.PHONE.PLACEHOLDER'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-form-text-input',{attrs:{"placeholder":_vm.$t('PORTAL.FORMS.ORDER.INPUT.PHONE.PLACEHOLDER'),"icon":_vm.$t('PORTAL.FORMS.ORDER.INPUT.PHONE.ICON'),"label":_vm.$t('PORTAL.FORMS.ORDER.INPUT.PHONE.LABEL'),"required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}),_c('base-form-message-line-error',{attrs:{"errors":errors}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }}},[_c('base-form-checkbox-input',{attrs:{"required":""},model:{value:(_vm.isAgreement),callback:function ($$v) {_vm.isAgreement=$$v},expression:"isAgreement"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('PORTAL.FORMS.ORDER.CHECKBOX.LICENCE_AGREEMENT'))}})])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }