<template>
  <label class="text-input"
         :class="{
            'text-input--iconic': icon,
            'text-input--labeled': label
          }"
  >
    <span> {{label}}<span class="input__required" v-show="required">*</span></span>
    <span class="input">
    <input :type="getType"
           :placeholder="placeholder"
           :value="value"
           @change="handleInput"
    />
    <base-icon :icon="icon"
               v-if="icon"/>
      </span>
  </label>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'
export default {
  name: 'BaseFormTextInput',
  components: { BaseIcon },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getType () {
      return this.isEmail ? 'email' : 'text'
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('change', value.target.value)
    }
  }
}
</script>
