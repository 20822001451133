<template>
    <div class="logo card__logo">
      <img
        :src="url"
        :alt="alt">
    </div>
</template>

<script>
export default {
  name: 'BaseCardPartLogo',
  props: {
    url: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
