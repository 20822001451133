<template>
    <base-card
      class="popular-article"
      is-vertical
      pointed>
        <base-card-part-title-content
          slot="card-title"
          :title="value.title"/>
      <base-card-part-logo
        slot="card-logo"
        v-if="getLogo"
        :alt="value.title"
        :url="getLogo"/>
      <plain-content
        :description="value.description"
        :time="getDateTime"
        :count-view="value.views"
      ></plain-content>
    </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleContent from '@/components/base/card/part/title/BaseCardPartTitleContent'
import PlainContent from '@/components/misc/content/PlainContent'
export default {
  name: 'SectionPartsArticlePopularsListItem',
  components: { PlainContent, BaseCardPartLogo, BaseCardPartTitleContent, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          title: undefined,
          logo: { url: undefined },
          description: undefined,
          views: undefined,
          proto: undefined
        }
      }
    }
  },
  computed: {
    getLogo () {
      return this.value?.logo?.url
    },
    getDateTime () {
      return this.value?.proto?.insert?.dtTm
    }
  }
}
</script>

<style scoped>

</style>
