<template>
    <base-card
      pointed
      is-vertical
    >
      <base-card-part-title-content
        slot="card-title"
        :title="value.title"/>
        <base-card-part-logo
          v-if="getLogo"
          :url="getLogo"
          :alt="value.title"
          slot="card-logo"/>
        <plain-content
          :title="value.title"
          :footer-show="false"
          :description="value.description"
        >
        </plain-content>
    </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleContent from '@/components/base/card/part/title/BaseCardPartTitleContent'
import PlainContent from '@/components/misc/content/PlainContent'
export default {
  name: 'SectionPartsArticleRecommendationListItem',
  components: { BaseCardPartTitleContent, PlainContent, BaseCardPartLogo, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          title: undefined,
          logo: undefined,
          description: undefined
        }
      }
    }
  },
  computed: {
    getLogo () {
      return this.value?.logo?.url
    }
  }
}
</script>

<style scoped>

</style>
