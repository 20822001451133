<template>
    <base-card-list>
        <section-parts-article-recommendation-list-item v-for="item in list"
                                                        :key="item.id"
                                                        :value="item"/>
    </base-card-list>
</template>

<script>
import BaseCardList from '@/components/base/card/BaseCardList'
import SectionPartsArticleRecommendationListItem
  from '@/components/section/parts-article/article-recommendation/SectionPartsArticleRecommendationListItem'
export default {
  name: 'SectionPartsArticleRecommendationList',
  components: { SectionPartsArticleRecommendationListItem, BaseCardList },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
