<template>
  <base-icon-text v-if="getTime"
                  :icon="$t(icon)">
    <slot>{{getTime}}</slot>
  </base-icon-text>
</template>

<script>
import BaseIconText from '@/components/base/iconText/BaseIconText'
import { C_LOCALS_DATE_FORMAT_TYPE } from '@/lib/constant/c.locales'

export default {
  name: 'BasePlainDate',
  components: { BaseIconText },
  props: {
    value: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: C_LOCALS_DATE_FORMAT_TYPE.full,
      validator: (value) => {
        return typeof C_LOCALS_DATE_FORMAT_TYPE[value] !== 'undefined'
      }
    },
    icon: {
      type: String,
      default: 'PORTAL.DEFAULTS.ICONS.DATE-TIME'
    }
  },
  computed: {
    getTime () {
      if (this.value) {
        return this.$d(new Date(this.value), this.format)
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
