<template>
  <validation-observer v-slot="{invalid}"
                       ref="vee-form-order">
    <base-form
      class="form-order form-order--input-light"
      :button-value="$t('PORTAL.FORMS.ORDER.SUBMIT.VALUE')"
      is-gup
      @submit="handleSubmit"
      :is-error="isRequestError"
      :button-disabled="invalid"
    >
      <base-form-messages-server-errros :store-path="$options.STORE_PATH"/>
      <validation-provider :name="$t('PORTAL.FORMS.ORDER.INPUT.NAME.PLACEHOLDER')"
                           mode="lazy"
                           rules="required"
                           v-slot="{errors}">
        <base-form-text-input :placeholder="$t('PORTAL.FORMS.ORDER.INPUT.NAME.PLACEHOLDER')"
                              :icon="$t('PORTAL.FORMS.ORDER.INPUT.NAME.ICON')"
                              :label="$t('PORTAL.FORMS.ORDER.INPUT.NAME.LABEL')"
                              v-model.trim="person"
                              required
        />
        <base-form-message-line-error :errors="errors"/>
      </validation-provider>
      <validation-provider :name="$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL')"
                           mode="lazy"
                           rules="email|required"
                           v-slot="{ errors }">
        <base-form-text-input :placeholder="$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL')"
                              :icon="$t('PORTAL.FORMS.ORDER.EMAIL.NAME.ICON')"
                              :label="$t('PORTAL.FORMS.ORDER.EMAIL.NAME.LABEL')"
                              v-model.trim="email"
                              required
                              is-email/>
        <base-form-message-line-error :errors="errors"/>
      </validation-provider>
      <validation-provider :name="$t('PORTAL.FORMS.ORDER.INPUT.PHONE.PLACEHOLDER')"
                           mode="lazy"
                           rules="required"
                           v-slot="{errors}">
        <base-form-text-input :placeholder="$t('PORTAL.FORMS.ORDER.INPUT.PHONE.PLACEHOLDER')"
                              :icon="$t('PORTAL.FORMS.ORDER.INPUT.PHONE.ICON')"
                              :label="$t('PORTAL.FORMS.ORDER.INPUT.PHONE.LABEL')"
                              v-model.trim="phone"
                              required
        />
        <base-form-message-line-error :errors="errors"/>
      </validation-provider>
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <base-form-checkbox-input v-model="isAgreement"
                                  required
        ><span v-html="$t('PORTAL.FORMS.ORDER.CHECKBOX.LICENCE_AGREEMENT')"/>
        </base-form-checkbox-input>
      </validation-provider>
    </base-form>
  </validation-observer>
</template>

<script>
import BaseFormCheckboxInput from '@/components/base/form/input/BaseFormCheckboxInput'
import BaseForm from '@/components/base/form/BaseForm'
import BaseFormTextInput from '@/components/base/form/input/BaseFormTextInput'
import BaseFormMessageLineError from '@/components/base/form/message/BaseFormMessageLineError'
import BaseFormMessagesServerErrros from '@/components/base/form/message/BaseFormMessagesServerErrros'
import { COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR } from '@/store/modules/common/requested/commot.requested.constant'
import {
  FORMS_ORDER_GETTER_GET_PHONE,
  FORMS_ORDER_GETTER_GET_MAIL,
  FORMS_ORDER_GETTER_GET_AGREEMENT,
  FORMS_ORDER_GETTER_GET_PERSON,
  FORMS_ORDER_MUTATION_SET_PHONE,
  FORMS_ORDER_MUTATION_SET_MAIL,
  FORMS_ORDER_MUTATION_SET_AGREEMENT,
  FORMS_ORDER_MUTATION_SET_PERSON, FORMS_ORDER_ACTION_SEND_DATA
} from '@/store/modules/forms/order/formsOrder.constant'
import { STORE_PATH_FORMS_ORDER } from '@/store/store.list'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'FormOrder',
  STORE_PATH: STORE_PATH_FORMS_ORDER,
  components: {
    BaseFormMessagesServerErrros,
    BaseFormMessageLineError,
    BaseForm,
    BaseFormCheckboxInput,
    BaseFormTextInput
  },
  computed: {
    ...mapGetters(STORE_PATH_FORMS_ORDER, [
      FORMS_ORDER_GETTER_GET_PHONE,
      FORMS_ORDER_GETTER_GET_MAIL,
      FORMS_ORDER_GETTER_GET_AGREEMENT,
      FORMS_ORDER_GETTER_GET_PERSON,
      COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR
    ]),
    person: {
      set (value) {
        this[FORMS_ORDER_MUTATION_SET_PERSON](value)
      },
      get () {
        return this[FORMS_ORDER_GETTER_GET_PERSON]
      }
    },
    email: {
      set (value) {
        this[FORMS_ORDER_MUTATION_SET_MAIL](value)
      },
      get () {
        return this[FORMS_ORDER_GETTER_GET_MAIL]
      }
    },
    phone: {
      set (value) {
        this[FORMS_ORDER_MUTATION_SET_PHONE](value)
      },
      get () {
        return this[FORMS_ORDER_GETTER_GET_PHONE]
      }
    },
    isAgreement: {
      set (value) {
        this[FORMS_ORDER_MUTATION_SET_AGREEMENT](value)
      },
      get () {
        return this[FORMS_ORDER_GETTER_GET_AGREEMENT]
      }
    },
    isRequestError () {
      return this[COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR]
    }
  },
  methods: {
    ...mapMutations(STORE_PATH_FORMS_ORDER, [
      FORMS_ORDER_MUTATION_SET_PHONE,
      FORMS_ORDER_MUTATION_SET_MAIL,
      FORMS_ORDER_MUTATION_SET_AGREEMENT,
      FORMS_ORDER_MUTATION_SET_PERSON
    ]),
    ...mapActions(STORE_PATH_FORMS_ORDER, [
      FORMS_ORDER_ACTION_SEND_DATA
    ]),
    async handleSubmit () {
      console.log('submitActual')
      await this[FORMS_ORDER_ACTION_SEND_DATA]()
      if (!this.isRequestError) {
        this.$refs['vee-form-order'].reset()
      }
    }
  }
}
</script>

<style scoped>

</style>
