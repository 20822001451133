<template>
    <ol class="form__server-error"
        v-if="isRequestError">
      <li v-for="(item, index) in getError" :key="index">
        {{item.msgText}}
      </li>
    </ol>
</template>

<script>
import {
  COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR,
  COMMON_REQUESTED_GETTER_ERRORS
} from '@/store/modules/common/requested/commot.requested.constant'

export default {
  name: 'BaseFormMessagesServerErrros',
  props: {
    storePath: {
      type: String,
      required: true
    }
  },
  computed: {
    isRequestError () {
      return this.$store.getters[`${this.storePath}/${COMMON_REQUESTED_GETTER_IS_REQUEST_ERROR}`]
    },
    getError () {
      return this.$store.getters[`${this.storePath}/${COMMON_REQUESTED_GETTER_ERRORS}`]
    }
  }
}
</script>
