<template>
    <section class="main main-article">
      <section-main-backgrounds
        :loading="false"
        :list="getBgList">
        <div class="bg__background-gradient"></div>
      </section-main-backgrounds>
      <div class="main-article__content">
      <base-card>
        <persona-content :member="getMembers"/>
      </base-card>
        <ul class="main-article__badges">
          <li><base-plain-date
            :format="getDateTimeFormat"
            :value="getTime"/>
<!--            <base-icon-text icon="clock">12 июня 2020</base-icon-text> -->
          </li>
          <li><base-icon-text :icon="$t('PORTAL.ARTICLE.ICONS.COUNT-VIEW')">{{getViews}}</base-icon-text> </li>
        </ul>
      </div>
    </section>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseIconText from '@/components/base/iconText/BaseIconText'
import BasePlainDate from '@/components/base/plainDate/BasePlainDate'
import PersonaContent from '@/components/misc/content/PersonaContent'
import SectionMainBackgrounds from '@/components/section/main/SectionMainBackgrounds'
import { C_LOCALS_DATE_FORMAT_TYPE } from '@/lib/constant/c.locales'
import {
  ARTICLE_GETTER_GET_MEMBER,
  ARTICLE_GETTER_GET_DATE_TIME_INSERT, ARTICLE_GETTER_GET_VIEWS
} from '@/store/modules/article/article.constant'
import { STORE_PATH_ARTICLE } from '@/store/store.list'
import { mapGetters } from 'vuex'
export default {
  name: 'SectionArticleMain',
  components: { BasePlainDate, PersonaContent, BaseIconText, BaseCard, SectionMainBackgrounds },
  computed: {
    ...mapGetters(STORE_PATH_ARTICLE, [
      ARTICLE_GETTER_GET_MEMBER,
      ARTICLE_GETTER_GET_DATE_TIME_INSERT,
      ARTICLE_GETTER_GET_VIEWS
    ]),
    getMembers () {
      return this[ARTICLE_GETTER_GET_MEMBER]
    },
    getViews () {
      return this[ARTICLE_GETTER_GET_VIEWS]
    },
    getTime () {
      return this[ARTICLE_GETTER_GET_DATE_TIME_INSERT]
    },
    getDateTimeFormat () {
      return C_LOCALS_DATE_FORMAT_TYPE.short
    },
    getBgList () {
      return [{
        url: '../../../assets/img/bgArticle.svg',
        id: `bg-${Math.round(Math.random() * 1000)}`
      }]
    }
  }
}
</script>

<style scoped>

</style>
