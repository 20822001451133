<template>
    <base-section class="section-article-content"
    :title="getTitle">
      <template v-slot:section-background>
        <base-background-item-square class="bg__square--left" is-green/>
        <base-background-item-square class="bg__square--right"/>
        <base-background-item-square class="bg__square--right-bottom"/>
        <base-background-item-blot/>
      </template>
    <article class="article" v-html="getContent">

    </article>
    <base-order-plain></base-order-plain>
      <form-order/>
    </base-section>
</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseSection from '@/components/base/section/BaseSection'
import FormOrder from '@/components/misc/forms/FormOrder'
import { ARTICLE_GETTER_GET_TITLE, ARTICLE_GETTER_GET_CONTENT } from '@/store/modules/article/article.constant'
import { STORE_PATH_ARTICLE } from '@/store/store.list'
import { mapGetters } from 'vuex'
import BaseOrderPlain from '../base/orderPlain/BaseOrderPlain.vue'
export default {
  name: 'SectionArticleContent',
  components: { FormOrder, BaseBackgroundItemBlot, BaseBackgroundItemSquare, BaseSection, BaseOrderPlain },
  computed: {
    ...mapGetters(STORE_PATH_ARTICLE, [ARTICLE_GETTER_GET_TITLE, ARTICLE_GETTER_GET_CONTENT]),
    getTitle () {
      return this[ARTICLE_GETTER_GET_TITLE]
    },
    getContent () {
      return this[ARTICLE_GETTER_GET_CONTENT]
    }
  }
}
</script>
