<template>
  <base-section
    :default-class="false"
    class="section-article-recommendation"
    :title="$t('PORTAL.SECTION.ARTICLES.RECOMMENDATION.TITLE')">
    <base-background slot="section-background">
      <base-background-item-astro/>
      <base-background-item-triangle is-green/>
      <base-background-item-oval class="bg__planet-one" is-light-blue/>
      <base-background-item-oval class="bg__planet-two" is-green/>
      <base-background-item-oval class="bg__oval-bottom" is-shine-green/>
      <base-background-item-ellipse/>
    </base-background>

    <base-loader v-if="isLoading"></base-loader>
      <section-parts-article-recommendation-list
        class="section-article-recommendation__list"
        :list="getList"
        v-else
      />

  </base-section>
</template>

<script>
import BaseBackground from '@/components/base/background/BaseBackground'
import BaseBackgroundItemAstro from '@/components/base/background/item/BaseBackgroundItemAstro'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import SectionPartsArticleRecommendationList
  from '@/components/section/parts-article/article-recommendation/SectionPartsArticleRecommendationList'
import {
  ARTICLES_RECOMMENDATION_ACTION_FETCH,
  ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST
} from '@/store/modules/articles/recommendation/articlesRecommendation.constant'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { STORE_PATH_ARTICLES_RECOMMENDATION } from '@/store/store.list'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SectionPartsArticleRecommendation',
  components: {
    BaseLoader,
    SectionPartsArticleRecommendationList,
    BaseBackgroundItemEllipse,
    BaseBackgroundItemOval,
    BaseBackgroundItemTriangle,
    BaseBackgroundItemAstro,
    BaseBackground,
    BaseSection
  },
  computed: {
    ...mapGetters(STORE_PATH_ARTICLES_RECOMMENDATION, [COMMON_LOADER_GETTER_IS_LOADING, ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST]),
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    },
    getList () {
      return this[ARTICLES_RECOMMENDATION_GETTER_GET_LIMITED_LIST]
    }
  },
  async created () {
    await this.getItems()
  },
  methods: {
    ...mapActions(STORE_PATH_ARTICLES_RECOMMENDATION, [ARTICLES_RECOMMENDATION_ACTION_FETCH]),
    async getItems () {
      await this[ARTICLES_RECOMMENDATION_ACTION_FETCH](this.$route.params.type)
    }
  }
}
</script>
