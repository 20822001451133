<template>
    <section>
      <slot name="breadcrumb"></slot>
    </section>
</template>

<script>
export default {
  name: 'ViewArticlesList'
}
</script>
