<template>
  <fragment>
    <template v-if="isArticle">
      <section-article-main
      />
      <base-section :default-gup="false" :default-class="false">
        <slot name="breadcrumb"></slot>
      </base-section>
      <div class="content-row content-article">
        <div class="content-row__main">
          <section-article-content/>
          <base-section :default-gup="false" :default-class="false">
            <base-sharing :networking="sharingNetworking"
                          :sharing="getSharingValues"
            />
          </base-section>
        </div>
        <div class="content-row__aside">
          <section-parts-article-populars/>
        </div>
      </div>

      <section-parts-article-recommendation/>
    </template>
  </fragment>
</template>
<!--https://twitter.com/intent/tweet?url=https%3A//youtu.be/xiwp1xOgdR4&text=%E3%80%90Q%26A%20MARSHMELLOWS%E3%80%91Let%27s%20%22Mellow%22%20Out%20with%20Some%20Questions%20and%20Answers%21%21%20%20...&via=YouTube&related=YouTube,YouTubeTrends,YTCreators-->
<script>
import BaseSection from '@/components/base/section/BaseSection'
import BaseSharing from '@/components/base/sharing/BaseSharing'
import mixinBreadcrumb from '@/components/mixin/mixinBreadcrumb'
import SectionPartsArticlePopulars from '@/components/section/parts-article/SectionPartsArticlePopulars'
import SectionPartsArticleRecommendation from '@/components/section/parts-article/SectionPartsArticleRecommendation'
import SectionArticleContent from '@/components/section/SectionArticleContent'
import SectionArticleMain from '@/components/section/SectionArticleMain'
import {
  ARTICLE_ACTION_FETCH,
  ARTICLE_GETTER_IS_ARTICLE,
  ARTICLE_GETTER_GET_TITLE, ARTICLE_GETTER_GET_CONTENT
} from '@/store/modules/article/article.constant'
import {
  SYSTEM_LOADING_MUTATION_TOGGLE_LOADING
} from '@/store/modules/system/loading/systemLoading.constant'
import { STORE_PATH_SYSTEM_LOADING, STORE_PATH_ARTICLE } from '@/store/store.list'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ViewsArticlesDefault',
  components: {
    SectionPartsArticleRecommendation,
    SectionPartsArticlePopulars,
    BaseSharing,
    BaseSection,
    SectionArticleContent,
    SectionArticleMain
  },
  mixins: [mixinBreadcrumb],
  data () {
    return {
      sharingNetworking: [
        { type: 'vk' },
        { type: 'ok' },
        { type: 'telegram' },
        { type: 'twitter' },
        { type: 'email' },
        { type: 'facebook' }
      ]
    }
  },
  async beforeRouteEnter (to, from, next) {
    // console.log(to)
    const { type, id } = to.params
    const store = from.matched[0]?.instances.default.$store
    if (!store) {
      next(async vm => {
        await vm.getItem({
          type,
          id
        })
      })
    } else {
      await store.dispatch(`${STORE_PATH_ARTICLE}/${ARTICLE_ACTION_FETCH}`, {
        type,
        id
      })
      next()
    }
  },
  computed: {
    ...mapGetters(STORE_PATH_ARTICLE, [
      ARTICLE_GETTER_IS_ARTICLE,
      ARTICLE_GETTER_GET_TITLE,
      ARTICLE_GETTER_GET_CONTENT
    ]),
    title () {
      return this[ARTICLE_GETTER_GET_TITLE]
    },
    isArticle () {
      return this[ARTICLE_GETTER_IS_ARTICLE]
    },
    getSharingValues () {
      const currentLocation = window.location
      const url = `${currentLocation.protocol}//${currentLocation.hostname}${currentLocation.pathname}${currentLocation.search}${currentLocation.hash}`
      const content = document.createElement('div')
      content.innerHTML = this[ARTICLE_GETTER_GET_CONTENT]
      return {
        url,
        title: this.title,
        description: content.innerText || content.textContent || undefined
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.setBreadcrumb()
    })
  },
  methods: {
    ...mapMutations(STORE_PATH_SYSTEM_LOADING, [SYSTEM_LOADING_MUTATION_TOGGLE_LOADING]),
    ...mapActions(STORE_PATH_ARTICLE, [ARTICLE_ACTION_FETCH]),
    setBreadcrumb () {
      this.mixinBreadcrumb_addItemToBreadcrumb({
        name: this.$route.name,
        params: this.$route.params,
        meta: {
          breadcrumb: this.title
        }
      })
    },
    /**
       *
       * @param {{type, id}} payload
       * @return {Promise<void>}
       */
    async getItem (payload) {
      // this[SYSTEM_LOADING_MUTATION_TOGGLE_LOADING](true)
      await this[ARTICLE_ACTION_FETCH](payload)
      this.setBreadcrumb()
    }
  }

}
</script>
