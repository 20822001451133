<template>
  <div>

    <router-view>
      <template v-slot:breadcrumb>
        <base-breadcrumb/>
      </template>
    </router-view>
  </div>

</template>

<script>
import BaseBreadcrumb from '@/components/base/breadcrumb/BaseBreadcrumb'
import { C_EVENTS_BREADCRUMB_INIT } from '@/lib/constant/c.events'
import { eventBus } from '@/lib/utils/eventBus'
export default {
  name: 'ViewsArticles',
  components: { BaseBreadcrumb },
  beforeRouteUpdate (to, from, next) {
    console.log('its work')
    eventBus.emit(C_EVENTS_BREADCRUMB_INIT)
    next()
  }
}
</script>
