<template>
  <base-section
    class="section-articles-popular-list"
    :title="$t('PORTAL.SECTION.ARTICLES.POPULAR.TITLE')"
  >
    <base-loader v-if="isLoading"></base-loader>
    <section-parts-article-populars-list
      :list="getList"
      v-else/>
  </base-section>
</template>

<script>
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import SectionPartsArticlePopularsList
  from '@/components/section/parts-article/article-popular/SectionPartsArticlePopularsList'
import {
  ARTICLES_POPULAR_GETTER_GET_LIMITED_LIST,
  ARTICLES_POPULAR_ACTION_FETCH
} from '@/store/modules/articles/popular/articlesPopular.constant'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { STORE_PATH_ARTICLES_POPULAR } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionPartsArticlePopulars',
  components: {
    BaseLoader,
    SectionPartsArticlePopularsList,
    BaseSection
  },
  async created () {
    await this.fetchItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_ARTICLES_POPULAR, [ARTICLES_POPULAR_GETTER_GET_LIMITED_LIST, COMMON_LOADER_GETTER_IS_LOADING]),
    getList () {
      return this[ARTICLES_POPULAR_GETTER_GET_LIMITED_LIST]()
    },
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    }
  },
  methods: {
    ...mapActions(STORE_PATH_ARTICLES_POPULAR, [ARTICLES_POPULAR_ACTION_FETCH]),
    async fetchItems () {
      await this[ARTICLES_POPULAR_ACTION_FETCH]({
        type: this.$route.params?.type
      })
    }
  }
}
</script>

<style scoped>

</style>
