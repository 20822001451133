<template>
    <a :href="getUrl"
       target="_blank"
       class="social-sharing__item">
      <base-icon-text :icon="getIcon">{{getValue}}</base-icon-text>
    </a>
</template>

<script>
import BaseIconText from '@/components/base/iconText/BaseIconText'
import { urlByType } from '@/lib/utils/sharing'

export default {
  name: 'BaseSharingItem',
  components: { BaseIconText },
  props: {
    icon: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    sharing: {
      type: Object,
      required: true
    }
  },
  computed: {
    getUpperType () {
      return this.type.toUpperCase()
    },
    getIcon () {
      return this.icon ? this.icon : this.$t(`PORTAL.DEFAULTS.SOCIAL-SHARING.${this.getUpperType}.ICON`)
    },
    getValue () {
      return this.value ? this.value : this.$t(`PORTAL.DEFAULTS.SOCIAL-SHARING.${this.getUpperType}.VALUE`)
    },
    getUrl () {
      const urlFunc = urlByType[this.type]
      if (urlFunc) {
        return urlFunc(this.sharing)
      }
      throw new Error(`Не существует функции для обработки шаринга в социальную сеть по типу ${this.type}`)
    }
  }
}
</script>

<style scoped>

</style>
