<template>
<form class="form"
      :class="{'form--gup': isGup, 'form--error': isError}">
<slot></slot>
  <div class="form__button-place">
    <slot name="form-button">
      <base-button
        :is-disabled="buttonDisabled"
        is-fill
        @click="handleSubmit"

      >
        {{$t('PORTAL.FORMS.ORDER.SUBMIT.VALUE')}}
      </base-button>
    </slot>
  </div>
</form>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
export default {
  name: 'BaseForm',
  components: { BaseButton },
  props: {
    isGup: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    buttonValue: {
      type: String,
      required: true
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSubmit () {
      // console.log('formSubmit')
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>
