<template>
  <div class="order-plain">
    <div class="order-plain__content">
      <h5 class="title">{{$t('PORTAL.BASE.ORDER-PLAIN.TITLE')}}</h5>
      <div class="description">{{$t('PORTAL.BASE.ORDER-PLAIN.DESCRIPTION')}}</div>
    </div>
    <div class="oder-plain__button">
      <base-button
        is-fill
        accent
      >
          {{$t('PORTAL.BASE.ORDER-PLAIN.BUTTON.VALUE')}}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton.vue'

export default {
  name: 'BaseOrderPlain',
  components: { BaseButton }
}
</script>
