import { SYSTEM_BREADCRUMB_MUTATION_ADD_ITEM } from '@/store/modules/system/breadcrumb/systemBreadcrumb.constant'
import { STORE_PATH_SYSTEM_BREADCRUMB } from '@/store/store.list'
import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations(STORE_PATH_SYSTEM_BREADCRUMB, [SYSTEM_BREADCRUMB_MUTATION_ADD_ITEM]),
    mixinBreadcrumb_addItemToBreadcrumb (routerItem) {
      this[SYSTEM_BREADCRUMB_MUTATION_ADD_ITEM](routerItem)
    }
  }
}
