<template>
   <nav class="breadcrumb">
      <ol v-if="getCrumbList">
        <li class="breadcrumb__item"
            v-for="crumb in getCrumbList"
            :key="crumb.name"
        >
          <router-link :to="{name: crumb.name, params: crumb.params || {}}">
            {{getTitle(crumb)}}
          </router-link>
          <base-icon icon="breadcrumb-arrow"/>
        </li>
      </ol>
   </nav>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'
import { C_EVENTS_BREADCRUMB_INIT } from '@/lib/constant/c.events'
import { eventBus } from '@/lib/utils/eventBus'
import {
  SYSTEM_BREADCRUMB_MUTATION_SET_LIST,
  SYSTEM_BREADCRUMB_GETTER_GET_LIST
} from '@/store/modules/system/breadcrumb/systemBreadcrumb.constant'
import { STORE_PATH_SYSTEM_BREADCRUMB } from '@/store/store.list'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'BaseBreadcrumb',
  components: { BaseIcon },
  mounted () {
    this.setInitRouterBreadcrumb()
  },
  created () {
    eventBus.on(C_EVENTS_BREADCRUMB_INIT, this.setInitRouterBreadcrumb)
  },
  destroyed () {
    eventBus.off(C_EVENTS_BREADCRUMB_INIT, this.setInitRouterBreadcrumb)
  },
  computed: {
    ...mapGetters(STORE_PATH_SYSTEM_BREADCRUMB, [SYSTEM_BREADCRUMB_GETTER_GET_LIST]),
    getCrumbList () {
      return this[SYSTEM_BREADCRUMB_GETTER_GET_LIST] || false
    }
  },
  methods: {
    ...mapMutations(STORE_PATH_SYSTEM_BREADCRUMB, [SYSTEM_BREADCRUMB_MUTATION_SET_LIST]),
    setInitRouterBreadcrumb () {
      this[SYSTEM_BREADCRUMB_MUTATION_SET_LIST](this.$route)
    },
    getTitle (crumb) {
      if (crumb.custom) {
        return crumb.breadcrumb
      }
      return this.$t(crumb.breadcrumb)
    }
  }
}
</script>
