export const urlByType = {
  twitter: (payload) => {
    const url = 'https://twitter.com/intent/tweet/'
    // 'url=%url&text=%text'
    const availableKeys = ['url', 'text']

    const MAX_LEN_TW = 140
    const siteUrl = payload.url
    let content = payload.title

    const length = (siteUrl + ' ' + content).length
    if (length > MAX_LEN_TW) {
      const availableLenhthContent = MAX_LEN_TW - (siteUrl.length + 4)
      if (availableLenhthContent > 0) {
        content = content.slice(0, availableLenhthContent) + '...'
      } else {
        content = ''
      }
    }
    payload.text = content
    return finalizeReplace(url, payload, availableKeys)
  },
  vk: (payload) => {
    const url = 'https://vk.com/share.php'
    // '?url=%url&title=%titile&description=%description&noparse=true'
    const availableKeys = ['url', 'description', 'image', 'title', 'noparse']
    payload.noparse = 'true'

    return finalizeReplace(url, payload, availableKeys)
  },
  facebook: (payload) => {
    const url = 'https://www.facebook.com/sharer/sharer.php'
    // ?u=@u&title=@t&description=@d&quote=@q&hashtag=@h'
    const availableKeys = ['u', 'description', 'title']
    const resultPayload = {
      ...payload,
      u: payload.url,
      description: payload.description,
      title: payload.title
    }
    return finalizeReplace(url, resultPayload, availableKeys)
  },
  ok: (payload) => {
    const url = 'https://connect.ok.ru/dk'
    // ?st.cmd=WidgetSharePreview&st.shareUrl=@u&st.comments=@t'
    const availableKeys = ['st.cmd', 'st.shareUrl', 'st.comments']
    const resultPayload = {
      'st.cmd': 'WidgetSharePreview',
      'st.shareUrl': payload.url,
      'st.comments': payload.description
    }
    return finalizeReplace(url, resultPayload, availableKeys)
  },
  telegram: (payload) => {
    const url = 'https://t.me/share/url'
    // ?url=@u&text=@t%0D%0A@d'
    const availableKeys = ['url', 'text']
    const resultPayload = {
      url: payload.url,
      text: `${payload.title} ${payload.description}`
    }
    return finalizeReplace(url, resultPayload, availableKeys)
  },
  email: (payload) => {
    const url = 'mailto:'
    // ?subject=@t&body=@u%0D%0A@d
    const availableKeys = ['subject', 'body']
    const resultPayload = {
      subject: payload.title,
      body: `${payload.url} ${payload.description}`
    }
    return finalizeReplace(url, resultPayload, availableKeys)
  }
}

function finalizeReplace (url, payload, availableKeys = []) {
  const keys = Object.keys(payload)
  let first = 1
  for (const key of keys) {
    if (!availableKeys.includes(key) || typeof payload[key] === 'undefined') {
      continue
    }
    let prefix = '&'
    if (first === 1) {
      prefix = '?'
      first++
    }
    url += `${prefix}${key}=${encodeURIComponent(payload[key])}`
  }
  return url
}
