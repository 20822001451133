<template>
    <base-card-list class="popular-article__list" is-column>
      <section-parts-article-populars-list-item v-for="item in list"
                                                :key="item.id"
                                                :value="item"/>
    </base-card-list>
</template>

<script>
import BaseCardList from '@/components/base/card/BaseCardList'
import SectionPartsArticlePopularsListItem
  from '@/components/section/parts-article/article-popular/SectionPartsArticlePopularsListItem'
export default {
  name: 'SectionPartsArticlePopularsList',
  components: { SectionPartsArticlePopularsListItem, BaseCardList },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
