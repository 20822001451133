<template>
    <div class="plain">
      <div class="plain__title">
        <slot name="title">{{title}}</slot>
      </div>
      <div class="plain__description"
          :class="{'plain__description--wide': isWideDescription}"
      >
        <slot> {{description}}</slot>
      </div>
      <div class="plain__footer" v-if="footerShow">
        <span class="hint">
          <base-plain-date
            :value="time"
            :icon="'PORTAL.PLAIN-CONTENT.FOOTER.TIME.ICON'"
            :format="dateFormat"
            >
          </base-plain-date>
        </span>
        <span class="hint">
          <base-icon-text :icon="$t('PORTAL.PLAIN-CONTENT.FOOTER.COUNT-VIEW.ICON')">
            <slot name="count-view">{{countView}}</slot>
          </base-icon-text>
        </span>
      </div>
    </div>
</template>

<script>
import BaseIconText from '@/components/base/iconText/BaseIconText'
import BasePlainDate from '@/components/base/plainDate/BasePlainDate'
import { C_LOCALS_DATE_FORMAT_TYPE } from '@/lib/constant/c.locales'
export default {
  name: 'PlainContent',
  components: { BasePlainDate, BaseIconText },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    countView: {
      type: Number,
      default: 0
    },
    footerShow: {
      type: Boolean,
      default: true
    },
    isWideDescription: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dateFormat () {
      return C_LOCALS_DATE_FORMAT_TYPE.full
    }
  }
}
</script>

<style scoped>

</style>
