<template>
  <div class="social-sharing"
       :class="{'social-sharing--opened': active}">
    <div class="social-sharing__item social-sharing__main-item"
         @click="handleOpenLinks">
      <base-icon-text :icon="$t('PORTAL.DEFAULTS.SOCIAL-SHARING.SHARE-BUTTON.ICON')">
        {{$t('PORTAL.DEFAULTS.SOCIAL-SHARING.SHARE-BUTTON.VALUE')}}
      </base-icon-text>
      <div class="main-item__arrow"></div>
    </div>
    <slide-x-left-transition :duration="600">
      <div class="social-sharing__list" v-show="active">
        <base-sharing-item v-for="(item,index) in networking"
                           :key="index"
                           :type="item.type"
                           :sharing="sharing"/>
      </div>
    </slide-x-left-transition>
  </div>
</template>

<script>
import BaseSharingItem from '@/components/base/sharing/BaseSharingItem'
import { SlideXLeftTransition } from 'vue2-transitions'
import BaseIconText from '@/components/base/iconText/BaseIconText'

export default {
  name: 'BaseSharing',
  components: {
    BaseSharingItem,
    BaseIconText,
    SlideXLeftTransition
  },
  data () {
    return {
      active: false
    }
  },
  props: {
    sharing: {
      type: Object,
      default: () => {
        return {
          url: '',
          description: '',
          title: '',
          image: '',
          via: ''
        }
      }
    },
    networking: {
      type: Array,
      default: () => {
        return [{
          icon: '',
          type: '',
          value: ''
        }]
      }
    }
  },
  computed: {
    getMetaDescription () {
      return this.sharing?.description
    }
  },
  methods: {
    handleOpenLinks () {
      this.active = !this.active
    }
  },
  metaInfo () {
    return {
      meta: [
        {
          property: 'og:title',
          content: this.sharing?.title
        },
        {
          property: 'og:description',
          content: this.sharing?.description
        },
        {
          property: 'og:url',
          content: this.sharing?.url
        },
        {
          name: 'twitter:title',
          content: this.sharing?.title
        },
        {
          name: 'twitter:description',
          content: this.sharing?.description
        },
        {
          property: 'twitter:url',
          content: this.sharing?.url
        }
      ]
    }
  }
}
</script>
