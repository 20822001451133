import mitt from 'mitt'
/**
 *  @event Emitter#c_events_modal_institution_open
 *  @event Emitter#c_events_breadcrumb_init
 *  @type {Emitter}
 *  @property {number} id
 */
const emitter = mitt()

export const eventBus = emitter
