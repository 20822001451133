<template>
    <span class="input__line-error" v-show="errors">{{errors[0]}}</span>
</template>

<script>
export default {
  name: 'BaseFormMessageLineError',
  props: {
    errors: {
      type: [Array, undefined],
      default: undefined
    }
  }
}
</script>
